@import "../../../styles/base";

.header {
  position: absolute;
  z-index: 99;
  width: 100%;
  pointer-events: none;
  touch-action: manipulation;
}

.logo {
  max-width: 172px;
  pointer-events: auto;
}

.fab {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: auto;
  touch-action: manipulation;
}

.mobileNavOuter {
  pointer-events: none;
}

.underlay {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: linear-gradient(160deg, rgba(255, 255, 255, 0) 30vh, rgba(255, 255, 255, 0.8) 50vh), rgba(255,255,255,0.8);
  transition: opacity 200ms ease-in-out;
}

.isOpen .underlay {
  opacity: 1;
}

.mobileNavInner {
  text-align: right;
  padding-bottom: 160px;
  position: fixed;
  right: 0;
  bottom: 0;
}

.isOpen .navItem,
.isOpen .underlay {
  pointer-events: auto;
}
