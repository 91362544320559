@import "../../styles/base";

.ddContainer {
  display: flex;
  flex: 1;
  margin-left: calc(var(--ds-spacing-m) * -1);
  margin-right: calc(var(--ds-spacing-m) * -1);
  flex-wrap: wrap;

  .item {
    flex: 1;
    padding: 0 var(--ds-spacing-m);
    min-width: 240px;
    max-width: 386px;
    margin-bottom: var(--ds-spacing-m);
  }
}

.do,
.dont {
  padding: var(--ds-spacing-m);
  position: relative;
  border-top: var(--ds-size-border-width-medium) solid var(--ds-color-neutral-500);
  border-radius: 4px;
  background: var(--ds-color-background-alt-100);
  margin-top: var(--ds-spacing-xs);
  .example {
    margin: calc(var(--ds-spacing-m) * -1);
    padding: var(--ds-spacing-m);
    display: flex;
    justify-content: center;

    img {
      align-self: flex-start;
    }
  }
}
.do {
  border-color: var(--ds-color-success-500);
}
.dont {
  border-color: var(--ds-color-error-500);
}

.link {
  color: var(--ds-color-primary);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
