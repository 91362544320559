@import '../../../styles/base.scss';

.examples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 500px));
  grid-gap: var(--ds-spacing-m);
}

.exampleImg {
  border: var(--ds-size-border-width-small) solid var(--ds-color-border-light);
  border-radius: var(--ds-radius-small);
  max-width: 500px;
}

.tableWrap {
  max-width: 100%;
  display: block;
  overflow-x: scroll;
}

.table {
  border: var(--ds-size-border-width-small) solid var(--ds-color-border);
  border-radius: var(--ds-radius-medium);
  overflow: hidden;
  border-collapse: separate;
  margin-top: var(--ds-spacing-l);
  width: 100%;

  tr {
    vertical-align: middle;
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  td,
  th {
    padding: var(--ds-spacing-m) var(--ds-spacing-l);
    border-bottom: var(--ds-size-border-width-small) solid var(--ds-color-border);
    border-right: var(--ds-size-border-width-small) solid var(--ds-color-border);
    vertical-align: middle;
    text-align: left;
    &:last-child {
      border-right: none;
    }
  }
  th {
    background: var(--ds-color-background-alt-100);
  }
}

.contentFooter {
  background-color: var(--ds-color-background-alt-100);
  margin: calc(var(--ds-layout-02) * -1);
  margin-top: var(--ds-layout-04);
  border-radius: 0 0 var(--ds-radius-medium) var(--ds-radius-medium);

  @media (min-width: 748px) {
    margin: calc(var(--ds-layout-04) * -1);
    margin-top: var(--ds-layout-04);
  }
}

.imageHoverContainer {
  position: relative;

  &:before {
    content: '';
    display: block;
    border-radius: 1px;
    box-shadow: 0 0 0 8px var(--ds-color-background-alt-100);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
    transform: scale(0.9);
    opacity: 0;
  }

  .imageHover {
    position: relative;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
    .imageHover {
      &:after {
        opacity: 1;
      }
    }
  }
}
