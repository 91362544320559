@import '../../styles/base';

.demo {
  position: relative;
  border-radius: var(--ds-radius-small) var(--ds-radius-small) 0 0;
  background: var(--ds-color-background-alt-50);
  border: var(--ds-size-border-width-small) solid var(--ds-color-background-alt-50);
  border-bottom: none;
  min-height: 240px;
}

.dark {
  background: var(--ds-color-background-ui-contrast);
  border-color: var(--ds-color-background-ui-contrast);
}

.none {
  background: none;
  border-color: var(--ds-color-border-light);
}

.demo:after {
  content: '';
  display: block;
  height: var(--ds-size-dimension-xxxs);
  position: absolute;
  bottom: 0;
  left: calc(-1 * var(--ds-size-border-width-small));
  right: calc(-1 * var(--ds-size-border-width-small));
}

.do:after {
  background-color: var(--ds-color-success-500);
}

.dont:after {
  background-color: var(--ds-color-error-500);
}

.caution:after {
  background-color: var(--ds-color-warning-500);
}
