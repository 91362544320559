@import 'variables.scss';

.flex {
  display: flex;
  justify-content: flex-start;
}

.row .item {
  margin-right: $unit;
}

.column {
  flex-direction: column;
}

.column .item {
  margin-bottom: $unit;
}
