@import "~normalize.css/normalize.css";
@import 'fonts/fontFace.scss';
@import 'variables.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
}

[type="button"] {
  -webkit-appearance: none;
}

body {
  font-family: var(--ds-font-family);
  color: var(--ds-color-text);
  @include textBody();
}
