@import "../../styles/base";

.code {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--ds-size-dimension-xxxl);
  }

  &.open {
    &:after {
      display: none;
    }
  }
}

.codeContent {
  overflow: hidden;
  position: relative;
  background: var(--ds-color-background-alt-100);
  /* box-shadow: 0 2px 0 0 inset var(--ds-color-border); */
}

.roundedCorners {
  border-radius: var(--ds-radius-small);
}

.buttonExpandCode {
  border-radius: 48px;
  position: absolute;
  z-index: 2;
  bottom: var(--ds-spacing-m);
  right: var(--ds-spacing-m);
  box-shadow: var(--ds-shadow-500);

  &.hidden {
    display: none;
  }
}
