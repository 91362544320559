@import 'core';

body {
  background-color: var(--ds-color-background-app);
}

.muted {
  color: var(--ds-color-text-muted);
}

.container {
  height: 100%;
  overflow: hidden;
  display: flex;
}
