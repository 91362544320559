@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(./inter-regular-webfont.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(./inter-semibold-webfont.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
