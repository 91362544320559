@import "../../styles/base";

.heroOuter {
  background-color: var(--ds-color-neutral-100);
  background-size: cover;
  background-position: top;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    height: 100vh;
  }
}

@media (min-width: 1024px) {
  .heroOuter {
    min-height: 640px;
    max-height: 900px;
    padding-bottom: 200px;
  }
}

.overlay {
  background-color: var(--ds-color-neutral-50);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 800ms linear;
  pointer-events: none;
}

.loading .overlay {
  opacity: 1;
}

.bannerDividerOuter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: var(--ds-color-background-app);
  box-shadow: 0 0 120px rgba(0,0,0,0.8);
}

.bannerDividerInner {
  width: 400%;
  transform: translateY(-98%);

  @media (min-width: 1024px) {
    width: 100%;
  }
}

.heroContent {
  text-align: center;
  margin: auto;
  padding-bottom: 120px;
  padding-top: 180px;
  max-width: 600px;
  position: relative;

  @media (min-width: 1024px) {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

.heroSpotlightOuter {
  bottom: 0;
  position: static;
  z-index: 2;
  left: 0;
  right: 0;

  @media (min-width: 1024px) {
    position: absolute;
  }
}

.searchTip {
  background: var(--ds-color-background-alt-500);
  border-radius: var(--ds-radius-medium);
  color: var(--ds-color-text-contrast);
}
