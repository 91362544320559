@import "../../styles/base";

.textContainer {

  &:first-child > *:first-child {
    margin-top: 0.5em;
  }

  hr {
    border: var(--ds-size-border-width-hairline) solid var(--ds-color-border);
    margin: 2em 0;
  }

  h1, h2, h3, h4, h5, h6 {
    max-width: 680px;
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  h1 + h4 {
    margin-top: 0;
  }

  p {
    max-width: 680px;
    margin-bottom: 1.5em;
  }

  ul {
    max-width: 40em;
  }
}
