@import "../../styles/base";

.searchContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 0;

  span {
    display: block;
  }
}

.clearIcon {
  position: absolute;
  right: 0;

  span {
    display: block;
  }
}

.searchInput  {
  border-radius: var(--ds-radius-pill);
  border: none;
  appearance: none;
  padding: var(--ds-spacing-m) var(--ds-spacing-l);
  padding-left: var(--ds-spacing-xxl);
  color: var(--ds-color-text);
  background: var(--ds-color-neutral-100);
  display: block;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--ds-input-placeholder-color, var(--ds-color-text-muted));
    opacity: 1;
  }
}

.label {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.hits {
  max-height: 500px;
  overflow: auto;
  -webkit-overflow-scroll: touch;
}
