.previewShape,
.previewSpacing,
.previewShadow,
.previewOverlay,
.previewInteraction,
.previewColor {
  min-width: var(--ds-size-dimension-xxl);
  min-height: var(--ds-size-dimension-xxl);
  background-size: cover;
  position: relative;
}

.previewSpacing {
  background-color: var(--ds-color-background-alt-50);
}

.previewShape,
.previewSpacing.dimension {
  background-color: var(--ds-color-information-300);
}

.previewColor {
  border-radius: var(--ds-radius-pill);
}

.previewOverlayCover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.previewTextColor {
  width: 100%;
}

.previewInteraction .previewOverlayCover {
  opacity: 0;
}

.previewInteraction:hover .previewOverlayCover {
  opacity: 1;
}

.previewFont {
  max-width: 220px;
  min-width: var(--ds-size-dimension-xxl);
  text-align: center;
  padding: var(--ds-spacing-m);
}

.previewSpacingSpacer {
  background: var(--ds-color-information-300);
  height: 16px;
}

.preview {
  min-width: calc(var(--ds-size-dimension-xxxxl) * 1.2 );
  min-height: calc(var(--ds-size-dimension-xxxxl) * 1.2 );
  margin-right: var(--ds-spacing-m);
  border-radius: var(--ds-radius-small);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 var(--ds-size-border-width-small) var(--ds-color-border-light);
}

.colorDetails {
  position: relative;
  margin-right: var(--ds-spacing-m);

  &:after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: var(--ds-radius-pill);
    background: var(--ds-color-background-alt-500);
    transform: translateX(var(--ds-spacing-xs));
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}
