@import '../../../styles/base.scss';

.Layout_container {
  max-width: 1024px;
}

.examples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 500px));
  grid-gap: var(--ds-spacing-xl);
}

.examplesNarrow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: var(--ds-spacing-xl);
}

.exampleImg {
  border: var(--ds-size-border-width-small) solid var(--ds-color-border-light);
  border-radius: var(--ds-radius-small);
  max-width: 500px;
}

.imageHoverContainer {
  position: relative;

  &:before {
    content: '';
    display: block;
    border-radius: 1px;
    box-shadow: 0 0 0 8px var(--ds-color-background-alt-100);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
    transform: scale(0.9);
    opacity: 0;
  }

  .imageHover {
    position: relative;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
    .imageHover {
      &:after {
        opacity: 1;
      }
    }
  }
}
