@import "../../../styles/base";

.header {
  position: absolute;
  width: 100%;
  z-index: 4;
  pointer-events: none;
}

.isSearchOpen {
  z-index: 99999999;
}

.logo {
  max-width: 232px;
}

.nav,
.logo {
  pointer-events: auto;
}

.searchOuter {
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 10vh;
  z-index: 4;
}

.searchInner {
  max-width: 480px;
}

.isSearchOpen .searchInner {
  pointer-events: auto;
}

.underlay {
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: linear-gradient(340deg, rgba(255, 255, 255, 0) 30vh, rgba(255, 255, 255, 0.8) 50vh), rgba(255,255,255,0.8);
  transition: opacity 200ms ease-in-out;
  pointer-events: none;
}

.isSearchOpen .underlay {
  pointer-events: auto;
  opacity: 1;
}
