@import '../../styles/base';

.panel {
  background-color: var(--ds-color-background-ui);
  border-radius: 0;
  min-height: 100vh;
  box-shadow: var(--ds-shadow-300);
  margin-top: 0;
  max-width: 1200px;
}

.panel + .panel {
  margin-top: var(--ds-spacing-xl);
}

@media screen and (min-width: $break-l) {
  .panel {
    border-radius: var(--ds-radius-medium);
    box-shadow: var(--ds-shadow-700);
    min-height: auto;
  }
}

.contentFooter {
  background: var(--ds-color-background-alt-50);
  border-radius: var(--ds-radius-medium)
}

.artistLink {
  text-decoration: none;
  color: inherit;
  position: relative;

  &:before {
    content: '';
    display: block;
    border-radius: var(--ds-radius-medium);
    box-shadow: 0 0 0 8px var(--ds-color-state-hover-overlay);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
    transform: scale(0.9);
    opacity: 0;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.artistLinkContent {
  position: relative;
  border-radius: var(--ds-radius-medium);
  background: var(--ds-color-background-ui);
  box-shadow: var(--ds-shadow-300);
}
