@import "../../styles/base";

.wrapper {
  background: var(--ds-color-background-alt-50);
  border-radius: var(--ds-radius-small);
}

.container {
  max-width: 600px;
}
