@import "../../styles/base";

.interactiveBox {
  position: relative;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  border-radius: var(--ds-radius-medium);

  &:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--ds-radius-small);
  }

  &:focus {
    outline: none;
    box-shadow: none;
    &:before {
      box-shadow: 0 0 0 4px var(--ds-color-state-focus-border);
      transform: scale(1);
      opacity: 1;
    }
  }
}

.isAnimated {

  &:before {
    transform: scale(0.9);
    opacity: 0;
    box-shadow: 0 0 0 8px var(--ds-color-state-hover-overlay);
    background: var(--ds-color-state-hover-overlay);
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
  }

  &:hover {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:focus {
    &:before {
      background: none;
    }
  }
}

.transparent {

  &:before {
    background: none;
    top: calc(-1 * var(--ds-spacing-xxs));
    right: calc(-1 * var(--ds-spacing-xxs));
    bottom: calc(-1 * var(--ds-spacing-xxs));
    left: calc(-1 * var(--ds-spacing-xxs));
  }
}

.pill {
  border-radius: var(--ds-radius-pill);
  &:before {
    border-radius: var(--ds-radius-pill);
  }
}

.interactiveArrow {
  transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);

  & > span {
    display: block;
  }
}

.interactiveBox:hover .interactiveArrow {
  transform: translateX(4px);
  color: var(--ds-color-primary);
}
