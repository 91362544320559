$fontFamily: var(--ds-font-family);
$fontWeight--light: var(--ds-font-weight-light);
$fontWeight--normal: var(--ds-font-weight-normal);
$fontWeight--bold: var(--ds-font-weight-bold);

@mixin textStyle($name) {
  font-size: var(--ds-font-size-#{$name});
  line-height: var(--ds-line-height-#{$name});
  font-weight: var(--ds-font-weight-#{$name});

  > strong {
    font-weight: var(--ds-font-weight-bold);
  }
};

@mixin textDisplay1() {
  @include textStyle('display1');
}

@mixin textDisplay2() {
  @include textStyle('display2');
}

@mixin textDisplay3() {
  @include textStyle('display3');
}

@mixin textDisplay4() {
  @include textStyle('display4');
}

@mixin textDisplay5() {
  @include textStyle('display5');
}

@mixin textDisplay6() {
  @include textStyle('display6');
}

@mixin textBody() {
  @include textStyle('body');
}

@mixin textBody2() {
  @include textStyle('body2');
}

@mixin textCaption() {
  @include textStyle('caption');
}

@mixin textMuted() {
  color: var(--ds-color-text-muted);
}
