@import 'variables.scss';

.promoMessageContainer {
  position: relative;
  height: 54px;
  background-color: var(--ds-color-background-alt-100);
  &.desktop {
    height: 60px;
  }
}

.promoMessage {
  background: var(--ds-color-expressive-green-100);
  position: relative;
  display: flex;
  color: var(--ds-color-expressive-green-700);
  width: 100%;
  z-index: $zAlwaysOnTop - 3;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: underline;
  }
}

.wrapper {
  padding: var(--ds-spacing-xs) var(--ds-spacing-s);
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.message {
  padding: 0;

  p {
    display: inline;
  }
}

.timeRemaining {
  height: 100%;
  min-width: 100px;
  margin-right: 0;
  color: var(--ds-color-information-700);
  display: flex;
  align-items: center;
}

.ends {
  margin-right: $unit * 0.25;
}

.desktop {
  strong {
    border-radius: $unit * 0.25;
    padding: $unit * 0.25 $unit * 0.5;
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--ds-spacing-xs);
    text-align: center;

    &:after,
    &:before {
      background-size: cover;
      background-image: url('./pop.gif');
      content: "";
      display: block;
      width: $unit * 2;
      height: $unit * 2;
    }

    &:after {
      transform: rotateY(-180deg);
    }
  }

  .timeRemaining {
    justify-content: center;
  }


  .message.messageOnly {
    &:after,
    &:before {
      display: none;
    }
  }
}

.reduceMotion {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  .desktop {
    .message:after,
    .message:before {
      background-image: url('./pop.png');
    }
  }

  .reduceMotion {
    display: flex;
  }
}
