.searchBoxRainbow {
  z-index: 2;
}

@keyframes borderAnimation {
  from {
    background-position-x: 0%;
  }

  to {
    background-position-x: 100%;
  }
}

.searchBoxRainbow:after {
  --borderWidth: 2px;
  content: "";
  background: linear-gradient(90deg,
      var(--ds-color-primary),
      var(--ds-color-expressive-pink-300),
      var(--ds-color-information-500),
      var(--ds-color-expressive-blue-500),
      var(--ds-color-expressive-green-300),
      var(--ds-color-expressive-yellow-500),
      var(--ds-color-primary));
  height: calc(100% + var(--borderWidth) * 2);
  left: calc(-1 * var(--borderWidth));
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  width: calc(100% + var(--borderWidth) * 2);
  z-index: -1;
  background-size: 50% 100%;
  background-repeat: repeat-x;
  border-radius: calc(var(--ds-radius-medium) * 0.7);

  animation: borderAnimation linear 3s infinite;
}

.inputWrapperRainbow {
  background-color: transparent !important;
  border-radius: var(--ds-radius-medium);
}

@media (prefers-reduced-motion) {
  .searchBoxRainbow:after {
    animation: none;
  }
}
