.promoBannerContainer {
  background-color: #FFE9A1;

  * > a {
    color: inherit;
    font-weight: var(--ds-font-weight-bold);
    text-decoration: underline;
  }
}

.wrappingBanner {
  text-align: center;
  word-wrap: break-word;
  text-wrap: wrap;
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  @media (prefers-reduced-motion) {
    display: flex;
  }

  &.reducedMotion {
    padding: 0px var(--ds-spacing-xs);
  }
}

.marqueeOuterContainer {
  display: auto;
  @media (prefers-reduced-motion) {
    display: none;
  }
}

.bannerDivider {
  display: inherit;
  margin: 0 var(--ds-spacing-m);
}

.promoBanner {
  display: flex;
  justify-content: center;
  align-items: center;
}
