@import '../../../styles/base.scss';

.code,
.paragraph {
  margin-bottom: var(--ds-spacing-m);
}

.heading {
  margin-bottom: var(--ds-spacing-m);
  margin-top: 48px;
}

.link {
  color: inherit;
  text-decoration: none;
  font-weight: var(--ds-font-weight-bold);

  &:hover {
    text-decoration: underline;
  }
}

.fontProperties {
  margin-left: var(--ds-spacing-m);
}

.table {
  border: var(--ds-size-border-width-small) solid var(--ds-color-border);
  border-radius: var(--ds-radius-medium);
  overflow: hidden;
  border-collapse: separate;
  margin-top: var(--ds-spacing-l);
  width: 100%;
  tr {
    vertical-align: middle;
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  td,
  th {
    padding: var(--ds-spacing-m) var(--ds-spacing-l);
    border-bottom: var(--ds-size-border-width-small) solid var(--ds-color-border);
    border-right: var(--ds-size-border-width-small) solid var(--ds-color-border);
    vertical-align: middle;
    text-align: left;
    &:last-child {
      border-right: none;
    }
  }
  th {
    background: var(--ds-color-background-alt-100);
  }
}
