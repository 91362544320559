@import 'variables.scss';

.cartCount {
  background: var(--ds-color-primary);
  color: var(--ds-color-text-contrast);
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50px;
  padding: 0 $unit * 0.25;
  min-width: $unit * 1.5;
  height: $unit * 1.5;
  border: 2px solid var(--ds-color-background-ui);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
}

.entering {
  animation: bounce 420ms linear 1;
  background-color: var(--ds-color-success-500);
}

.entered {
  animation: none;
  background-color: var(--ds-color-success-500);
}

@keyframes bounce {
  0% {
    transform: scale(1);
    font-size: 10px;
    color: rgba(255, 255, 255, 1);
  }
  80% {
    transform: scale(1.4);
    font-size: 2px;
    color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    font-size: 10px;
    color: rgba(255, 255, 255, 1);
  }
}
