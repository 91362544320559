@import 'variables.scss';

.listHorizontal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin-right: $unit;

    &:last-child {
      margin-right: 0;
    }
  }
}

.appstoreGraphic {
  display: block;
  max-height: 40px;
  width: auto;
}

.footerContainer {
  max-width: 1180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footerBottom {
  margin-left: auto;
  width: 100%;

  @media only screen and (min-width: $break-m) {

    ul {
      width: 100%;
      justify-content: center
    }
  }
}
