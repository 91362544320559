/*
 * Most colors are defined in ranges where the core color is
 * in the middle (500). Lighter colors are 50 -> 400 and darker
 * are 600 -> 900.
 *
 * The scales are not all complete. Items yet to be defined on the scale default to pink to indicate to consumers
 * that they are not implemented. Where possible, avoid adding new colors to the scales.
 *
 */


// Black / White
$white: #FFFFFF;
$black: #000000;

// Redbubble Logo Brand Color
$logo: #E41421;

// Fiery Rose
$primary100: pink;
$primary200: pink;
$primary300: #FF8696;
$primary400: pink;
$primary500: #FF596F;
$primary600: #E85063;
$primary700: pink;
$primary800: pink;
$primary900: pink;
$primary: $primary500;

// Dusky Purple
$secondary100: pink;
$secondary200: pink;
$secondary300: pink;
$secondary400: pink;
$secondary500: #40354E;
$secondary600: pink;
$secondary700: #1A1239;
$secondary800: pink;
$secondary900: pink;
$secondary: $secondary500;

// Cold Clay
$neutral50: #F9F9FB;
$neutral100: #F2F2F6;
$neutral200: #D6DADF;
$neutral300: #B8BFCA;
$neutral400: pink;
$neutral500: #8C95A5;
$neutral600: pink;
$neutral700: pink;
$neutral800: pink;
$neutral900: pink;
$neutral: $neutral500;

// Success
$success100: #DBEAE8;
$success200: #B5D2CE;
$success300: pink;
$success400: pink;
$success500: #47CCCC;
$success600: #37B5B5;
$success700: #154F50;
$success800: pink;
$success900: pink;
$success: $success500;

// Information
$information100: #E7E4F7;
$information200: #C8BFF9;
$information300: #A397E1;
$information400: pink;
$information500: #6551CC;
$information600: #5441B5;
$information700: #3B2871;
$information800: pink;
$information900: pink;
$information: $information500;

// Warning
$warning100: #FFF4EB;
$warning200: #FFD6AE;
$warning300: pink;
$warning400: pink;
$warning500: #FFB266;
$warning600: #E8A15B;
$warning700: #653E18;
$warning800: pink;
$warning900: pink;
$warning: $warning500;

// Error
$error100: #FCEDF1;
$error200: #FFC3C3;
$error300: pink;
$error400: pink;
$error500: #DC305B;
$error600: #CA2C54;
$error700: #3F111C;
$error800: pink;
$error900: pink;
$error: $error500;

// Abstractions over the base color palette
$focusColor: $information300;

/*
TODO(RD): I feel like we should do something like this, but does it make sense to put it in:
   - A variable?
   - A mixin?
   - A utility class?

$focusOutline: outline 4px $focusColor;
$focusBoxShadow: 0 0 0 4px $focusColor;
*/


