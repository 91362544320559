@import 'variables.scss';

.navContainer {
  -webkit-overflow-scrolling: touch;
}

.itemHero {
  background-size: cover;
  background-position: center right;
  color: var(--ds-color-text-contrast);
}

.itemHeroOverlay {
  background: linear-gradient(transparent, rgba(0,0,0,0.2));
}
