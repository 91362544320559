@import 'variables.scss';

.collapsible {
  width: 100%;
  overflow: hidden;
}

.animatedContents {
  overflow: hidden;
}

