@import 'variables.scss';

.iconPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.iconWrapper {
  position: relative;
  border-radius: var(--ds-radius-pill);

  &:hover {
    z-index: 2;
    .tooltip {
      opacity: 1;
      transform: translate(-50%, -120%);
    }
  }
}

.tooltip {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -100%);
  opacity: 0;
  background: var(--ds-color-background-ui-contrast);
  color: var(--ds-color-text-contrast);
  border-radius: var(--ds-radius-pill);
  box-shadow: var(--ds-shadow-500);
  transition: transform var(--ds-transition-standard) cubic-bezier(0.380, 1.010, 0.455, 1.130);
  pointer-events: none;

  & div {
    display: flex;
  }
}
