@import '../../styles/base.scss';

.outlinedContainer {
  border-radius: var(--ds-radius-medium);
  background: var(--ds-color-background-alt-50);
  overflow: hidden;

  &:hover {
    background: var(--ds-color-background-ui);
  }
}

.peak {
  padding-bottom: 160px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--ds-radius-small) var(--ds-radius-small) 0 0;
  transform: translateY(8px);
  transition: all 600ms cubic-bezier(0.2, 1, 0.4, 1);
}

.outlinedContainer:hover .peak {
  transform: translateY(0);
  box-shadow: 0 10px 64px -24px rgba(0, 0, 0, 0.2);
}

.cardImage {
  padding: var(--ds-spacing-m);
  background-size: auto 80px;
  background-repeat: no-repeat;
  background-position: right var(--ds-spacing-m) bottom -26px;
}

.interactiveArrow {
  transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
}

.cardImage:hover {
  .interactiveArrow {
    transform: translateX(4px);
    color: var(--ds-color-primary);
  }
}

@media (min-width: 1024px) {
  .cardImage {
    background-size: auto 120px;
    background-position: right var(--ds-spacing-m) bottom -40px;
  }
}

.coverImage {
  position: relative;
  padding-bottom: 50%;
  background-color: var(--ds-color-background-alt-50);
  background-size: cover;
  background-position: center;
  border-radius: var(--ds-radius-small);

  &:hover {
    background-color: var(--ds-color-white);
  }
}

.coverLabel {
  position: absolute;
  bottom: 0;
}

.browserBar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: var(--ds-size-dimension-m);
  background: var(--ds-color-neutral-200);
  background: white;
  border-bottom: var(--ds-size-border-width-hairline) solid var(--ds-color-border-light);
  border-radius: var(--ds-radius-medium) var(--ds-radius-medium) 0 0;
}

.browserAction {
  height: var(--ds-size-dimension-xs);
  width: var(--ds-size-dimension-xs);
  border-radius: var(--ds-radius-pill);
  background: var(--ds-color-neutral-100);
  margin-left: var(--ds-spacing-xxs);

  &:nth-child(1) {
    background: var(--ds-color-success-500);
  }
}
