@import "../../styles/base";

.banner {
  background-color: var(--ds-color-background-alt-50);
  background-size: 600px;
  background-position: center top 40%;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
}

.bannerDivider {
  position: absolute;
  bottom: -1px;
}

.bannerInner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay {
  background-color: var(--ds-color-neutral-50);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
  box-shadow: inset 0 -100px 80px -100px rgba(0,0,0,0.1);
  transition: all 800ms linear;
}

.open .overlay,
.loading .overlay {
  opacity: 1;
  transition-duration: 200ms;
}

.attribution {
  width: 100%;
  height: 70vh;
  position: absolute;

  @media (min-width: 1024px) {
    height: 90vh;
  }
}

.artistAttribution {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0);
  transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
  transition-delay: 0;
}

.open .artistAttribution {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(-16px);
  transition: all 900ms cubic-bezier(0.2, 1, 0.4, 1);
  transition-delay: 400ms;

  @media (min-width: 1024px) {
    transform: translateY(-32px);
  }
}

.container {
  position: relative;
  text-align: center;
  transform: scale3d(0.5, 0.5, 1);
  transform-origin: top;
  opacity: 0;
  transition: all 600ms cubic-bezier(0.2, 1, 0.4, 1);
}

.open .container {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
}

.open .close {
  opacity: 1;
}

.image {
  height: 40vh;
  box-shadow: 0 64px 160px -24px rgba(0,0,0,0.1);

  img {
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    height: 70vh;
    position: relative;
    bottom: -16px;
  }
}

.avatar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  line-height: 0;
}

.trigger {
  position: absolute;
  top: 0;
  z-index: 2;
  right: var(--ds-spacing-l);
  transform: translate(0, -50%);
  transition: all 600ms cubic-bezier(0.2, 1, 0.4, 1);
  opacity: 1;

  @media (min-width: 1024px) {
    right: 50%;
    transform: translate(50%, -50%);
  }

  &.hidden {
    transform: translate(0, -60%);
    opacity: 0;

    @media (min-width: 1024px) {
      transform: translate(50%, -60%);
    }
  }
}

.open .trigger {
  transform: translate(0, 25%);

  @media (min-width: 1024px) {
    transform: translate(50%, 25%);
  }
}

.customButton {
  appearance: none;
  border: none;
  background: transparent;
  border-radius: 100vh;
  width: var(--ds-size-dimension-xxxl);
  height: var(--ds-size-dimension-xxxl);
  color: var(--ds-color-white);
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    background: rgba(0,0,0,0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100vh;
    transition: all 600ms cubic-bezier(0.2, 1, 0.4, 1);
  }

  &:hover {
    &:before {
      background: rgba(0,0,0,0.2);
    }
  }
}

.open .customButton {
  &:before {
    transform: scale(0.6);
    background: rgba(0,0,0,0.2);
  }
}

.customIcon {
  transform: translateY(50%);
  transition: all 600ms cubic-bezier(0.2, 1, 0.4, 1);
}

.open .customIcon {
  transform: translateY(0);
}
