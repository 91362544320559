@import 'variables.scss';

/* Global defenitions, consider moving to components */

.link {
  text-decoration: none;
  color: inherit;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

.listHorizontal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin-right: $unit;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Layout specific styles */

.footer {
  width: 100%;
  background-color: var(--ds-color-background-app-dark);
}

.footerContainer {
  max-width: 1180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footerBottom {
  margin-left: auto;
  width: 100%;
  color: var(--ds-color-text-muted-contrast);

  .footerContainer {
    padding: $unit * 1.5 $unit;
  }

  @media only screen and (min-width: $break-m) {

    .footerContainer {
      padding: $unit * 1.5 $unit * 1.5 $unit * 2;
    }

    ul {
      width: 100%;
      justify-content: center
    }
  }
}
