@import "../../styles/base";

@keyframes pulse1 {
  0% { transform: scale(1); opacity: 0.6; }
  50% { transform: scale(2); opacity: 0; }
  100% { transform: scale(2); opacity: 0; }
}

@keyframes pulse2 {
  0% { transform: scale(1); opacity: 0.2; }
  90% { transform: scale(2.5); opacity: 0; }
  100% { transform: scale(2.5); opacity: 0; }
}

@keyframes arrow1 {
  0% { transform: translateX(40px) scale(1); opacity: 0.8; }
  90% { transform: translateX(160px) scale(0.9); opacity: 0; }
  100% { transform: translateX(160px) scale(0.9); opacity: 0; }
}

@keyframes arrow2 {
  0% { transform: translateX(40px) scale(1); opacity: 0.8; }
  90% { transform: translateX(120px) scale(0.9); opacity: 0; }
  100% { transform: translateX(120px) scale(0.9); opacity: 0; }
}

$rangeSliderHeight: 60px;

.sliderContainer {
  position: relative;
}

.imageContainer {
  background-color: var(--ds-color-background-alt-100);
  border-radius: var(--ds-radius-medium);
}

.range {
  height: $rangeSliderHeight;
  width: 100%;
  position: absolute;
  transform: translateY(-$rangeSliderHeight);
  cursor: default;
}

.rangeAnchor {
  position: relative;
}

.slider {
  padding: 0;
  position: relative;
  background-size: cover;
  background-position: center;
  padding-bottom: 50%;
  margin-top: $rangeSliderHeight;

  .handle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ds-color-background-ui);
    border-radius: var(--ds-radius-pill);
    border: 4px solid var(--ds-color-background-ui);
    box-shadow: var(--ds-shadow-700);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center center;
    height: 48px;
    width: 48px;
    position: relative;
    z-index: 4;
  }

  &.pulse {
    .handle:before,
    .handle:after {
      content: "";
      position: absolute;
      display: block;
      transform: translateX(40px);
      opacity: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-left: 10px solid var(--ds-color-background-ui);

      animation-name: arrow1;
      animation-duration: 2400ms;
      animation-delay: 200ms;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0,0,0.5,1.75);
    }
    .handle:after {
      animation-name: arrow2;
      animation-delay: 600ms;
    }
    .handleContainer {
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--ds-color-information-500);
        border-radius: 50%;
        width: 48px;
        height: 48px;

        animation-name: pulse1;
        animation-duration: 2400ms;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(-0.5,1,0.5,1.75);
      }
      &:after {
        animation-delay: 200ms;
        animation-name: pulse2;
      }
    }
  }

  :global {
    .rc-slider-handle {
      width: 48px;
      height: 48px;
      margin-left: 2px;
      margin-right: 0;
      top: calc(50% - 24px);
      background: transparent;
      border: none;

      &:active {
        box-shadow: 0 0 0 4px var(--ds-color-state-focus-border);
      }
    }
    .rc-slider-track {
      background-color: transparent;
      border-radius: 0;
      opacity: 1;
      height: 100%;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: -$rangeSliderHeight * 0.25;
        right: -2px;
        transform: translate(50%, -50%);
        width: 12px;
        height: 12px;
        background: var(--ds-color-information-500);
        border-radius: 200vh;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        right: -2px;
        bottom: 0;
        top: -$rangeSliderHeight * 0.25;
        transform: translate(50%, 0);
        width: 4px;
        background: var(--ds-color-information-500);
      }
    }
    .rc-slider-rail {
      height: 100%;
      background: transparent;
    }
  }
}

.rangeSteps {
  background: url(rangeLine.svg);
  background-repeat: space no-repeat;
  background-position: center;
  background-size: 48px;
}
