@import '../../../styles/base.scss';

.grid {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 100%;
    min-width: 220px;
    max-width: 360px;
    text-decoration: none;
    color: inherit;
    margin-right: var(--ds-spacing-m);
    margin-bottom: var(--ds-spacing-m);

    &.itemActive {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        border-radius: var(--ds-radius-small) 0 0 var(--ds-radius-small);
        background: var(--ds-color-primary);
      }
    }
  }
}
