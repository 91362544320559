.wrapper {
  width: 100%;
}

.sidebarContainer {
  margin: calc(var(--ds-spacing-xl) * -1);
  margin-left: var(--ds-layout-02);
  padding: var(--ds-spacing-xl);
  border-radius: 0 var(--ds-radius-medium) var(--ds-radius-medium) 0;
  min-width: 30%;
  position: relative;
}

.sidebar {
  position: sticky;
  top: var(--ds-spacing-l);
}

.tocLink {
  text-decoration: none;
  color: var(--ds-color-text);
  padding: var(--ds-spacing-xxxs) var(--ds-spacing-s);
  margin: 0 calc(var(--ds-spacing-s) * -1);
  border-radius: var(--ds-radius-pill);

  &:hover {
    background: var(--ds-color-background-alt-100);
  }
}

.tocLinkActive {
  color: var(--ds-color-primary);
}
