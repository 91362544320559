@import '../../../styles/base.scss';

.paletteContainer {
  margin-top: calc(-1 * var(--ds-size-dimension-xxs));
  text-transform: capitalize;

  @media (min-width: $break-m) {
    margin-top: calc(-1 * var(--ds-size-dimension-m));
  }

  &:first-child {
    margin-top: 0;
  }
}

.paletteSwatchOuter {
  min-width: var(--ds-size-dimension-xxxl);
  width: var(--ds-size-dimension-xxxl);
  height: var(--ds-size-dimension-xxxl);
  border-radius: var(--ds-radius-pill);

  &.transparent {
    background-image: url('./alpha.svg');
    background-size: 8px;
  }
}

.paletteSwatchInner {
  width: 100%;
  height: 100%;
  border-radius: var(--ds-radius-pill);

  &.border {
    box-shadow: inset 0 0 0 1px var(--ds-color-border);
  }
}

.monoLabel {
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
  text-transform: uppercase;
  background: var(--ds-color-background-alt-100);
  padding: var(--ds-spacing-xxxs) var(--ds-spacing-xxs);
  border-radius: var(--ds-radius-small);
}
