@import '../../../styles/base.scss';

.content {
  max-width: 900px
}

.indexContainer {
  padding: var(--ds-spacing-xl) 0;
}

.indexList {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: "";
    display: "block";
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    background: var(--ds-color-border);
  }
}

.indexListItem {
  position: relative;
  padding: var(--ds-spacing-xxxs) 0;

  &:before {
    content: "";
    display: "block";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: var(--ds-color-primary);
    opacity: 0;
  }
}

.indexListItem.active {
  &:before {
    opacity: 1;
  }
}

.imageHoverContainer {
  position: relative;

  &:before {
    content: '';
    display: block;
    border-radius: 1px;
    box-shadow: 0 0 0 8px var(--ds-color-state-hover-overlay);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
    transform: scale(0.9);
    opacity: 0;
  }

  .imageContainer {
    border-bottom: var(--ds-size-border-width-small) solid var(--ds-color-border-light);
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.imageGallery {
  border-radius: var(--ds-radius-medium);
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  grid-auto-rows: minmax(100px, auto);
}

.imageGalleryItem {
  position: relative;
}

.badge {
  background: var(--ds-color-information-500);
  width: var(--ds-size-dimension-m);
  height: var(--ds-size-dimension-m);
  border-radius: var(--ds-size-dimension-m);
  color: var(--ds-color-text-contrast);

  &.absolute {
    position: absolute;
    top: var(--ds-spacing-m);
    right: var(--ds-spacing-m);
  }
}
