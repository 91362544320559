@import 'variables.scss';

.scrollingTextItem {
  padding-top: var(--ds-spacing-m);
  padding-bottom: var(--ds-spacing-m);
  height: 100%;
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  margin: 0;
  font-size: var(--ds-font-size-body2);
  line-height: var(--ds-line-height-body2);
  font-weight: var(--ds-font-weight-body2);
  color: var(--ds-color-text);
}

/*
  we are using
  opacity:0 instead of
  display:none,
  because the css animations will re play when this container transitions
  away from display:none
*/
.scrollingTextHidden {
  opacity: 0
}

.scrollingTextContainer {
  padding-left: var(--ds-spacing-m);
  color: var(--ds-color-text-muted);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.typedTextAnimation {
  animation: typing 1.5s forwards linear;
}

@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 450px;
  }
}
