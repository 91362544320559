.star1, .star2, .star3 {
  position: absolute;
}

.star1 {
  animation: rainbow 8s infinite;
}

.star2 {
  animation: rainbow reverse 5s infinite;
}

.star3 {
  animation: rainbow alternate 7s infinite;
}

@keyframes rainbow {
  0% {
    color: var(--ds-color-holiday-rainbow-red);
    transform: scale(1);
  }

  16% {
    color: var(--ds-color-holiday-rainbow-orange);
  }
  32% {
    color: var(--ds-color-holiday-rainbow-turqoise);
    transform: scale(1.3);
  }
  50% {
    color: var(--ds-color-holiday-rainbow-green);
  }
  66% {
    color: var(--ds-color-holiday-rainbow-blue);
    transform: scale(1);
  }
  84% {
    color: var(--ds-color-holiday-rainbow-orange);
    transform: scale(1.3);
  }
  100% {
    color: var(--ds-color-holiday-rainbow-red);
    transform: scale(1);
  }
}

@media (prefers-reduced-motion) {
  .star1, .star2, .star3 {
    animation: none;
  }
}
