@import "../../styles/base";

.example {
  position: relative;
  padding-bottom: var(--ds-layout-06);
  width: 100%;
}

.exampleBody {
  border: var(--ds-size-border-width-small) solid var(--ds-color-border);
  border-radius: var(--ds-radius-medium);
}

.visual {
  overflow: auto;
  max-width: 100%;
  background: var(--ds-color-background-app-light);
  padding: var(--ds-spacing-l) var(--ds-spacing-m);
  position: relative;
  border-radius: var(--ds-radius-medium) var(--ds-radius-medium) 0 0;

  &.muted {
    background: var(--ds-color-background-alt-100);
  }
  &.dark {
    background: var(--ds-color-background-ui-contrast);
    border-color: var(--ds-color-background-ui-contrast);
  }
}

.exampleConfig {
  padding: var(--ds-spacing-m);
}

.overflowVisual {
  overflow: visible;
}

.visualContainer {
  display: block;
}

.name {
  margin-bottom: var(--ds-spacing-xs);
}

.description {
  margin-bottom: var(--ds-spacing-m);
  margin-top: 0;
  max-width: 600px;

  > code,
  > pre {
    background: var(--ds-color-background-alt-100);
    border-radius: var(--ds-radius-small);
    padding: var(--ds-spacing-xxs) var(--ds-spacing-xs);
  }
}

.codeToolbar {
  display: flex;
  justify-content: flex-end;
  padding: var(--ds-spacing-xs) var(--ds-spacing-m);
  border-top: var(--ds-size-border-width-small) solid var(--ds-color-border);
}

.isFullScreen.example {
  position: static;
}

.isFullScreen.example .visualContainer {
  background-color: var(--ds-color-background-ui);
  height: 100%;
  left: 0;
  position: fixed;
  top: 48px;
  width: 100%;
  z-index: 10;
}

.isFullScreen.example .codeToolbar {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  border-top: none;
  border-radius: 0;
  z-index: 11;
  width: 100%;
  background-color: var(--ds-color-background-ui);
}
