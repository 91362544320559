.marqueeContainer {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  &:hover,
  &:active {
    > .marquee {
      animation-play-state: paused;
    }
  }
}

.marquee {
  flex: 0 0 auto;
  min-width: auto;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.initialChildContainer {
  flex: 0 0 auto;
  display: flex;
  min-width: auto;
  flex-direction: row;
  align-items: center;
}
