@import 'variables.scss';

.trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  padding: $unit;
  border: 0px;
  border-radius: 4px;
  background-color: $white;

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 4px $focusColor;
  }
}

.triggerArrow {
  display: inline-block;
}
