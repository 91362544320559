@import './styles/base.scss';

.footer {
  border-top: var(--ds-size-border-width-small) solid var(--ds-color-border-light);
}

.footerTop {
  background-image: url('./images/footer-painter-mobile.svg');
  background-position: bottom right;
  background-size: 70%;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) {
  .footerTop {
    background-size: contain;
    background-image: url('./images/footer-painter-desktop.svg');
  }
}

.footerBottom {
  background: var(--ds-color-background-alt-50);
}

.avatarContainer {
  line-height: 0;
}

.logo {
  width: 40px;
}

.main {
  max-width: 900px;
}
