@import '../../../styles/base.scss';

.content {
  max-width: 900px;
}

.imageHoverContainer {
  position: relative;

  &:before {
    content: '';
    display: block;
    border-radius: 1px;
    box-shadow: 0 0 0 8px var(--ds-color-state-hover-overlay);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.2, 1, 0.4, 1);
    transform: scale(0.9);
    opacity: 0;
  }

  .imageContainer {
    border-bottom: var(--ds-size-border-width-small) solid var(--ds-color-border-light);
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
